import React, { useEffect } from "react";
import Header from "../components/MarketingHeader";
import Achieva from "../images/Marketing/achieva-dark.svg";
import AchievaLogo from "../images/Clients/Achieva.jpg";
import KratosLogo from "../images/Clients/Kratos.jpg";
import CallectiveLogo from "../images/Clients/Callective.jpg";
import RedCrossLogo from "../images/Clients/Salvation.jpg";
import NTCLogo from "../images/Clients/Ntc-main.jpg";
import KnowLogo from "../images/Clients/Knowbe4.jpg";
import David from "../images/Marketing/david.jpg";
import Mike from "../images/Marketing/mike.jpg";
import UET from "../images/Marketing/UET-logo-black.svg";
import { Helmet } from "react-helmet";
import AnchorLink from "react-anchor-link-smooth-scroll";

const MarketingPartners = () => {
  return (
    <>
      <Helmet>
        <title>
          Consult FGC | Digital Marketing Agency | Website Design & Development
        </title>
        <link
          rel="icon"
          type="image/x-icon"
          href="https://consultfgc.netlify.app/static/ConsultFGC_Logo_footer-8b0c6cdcc10d6cc56b61a7b78ed851b7.png"
        ></link>
        <link
          data-react-helmet="true"
          rel="canonical"
          href="https://consultfgc.com/"
        ></link>
        <meta data-react-helmet="true" charset="utf-8"></meta>
        <meta
          data-react-helmet="true"
          name="description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        ></meta>
        <meta
          data-react-helmet="true"
          name="robots"
          contet="max-snippet:-1, max-image-preview:large, max-video-pewview:-1"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:locale"
          content="en-US"
        ></meta>
        <meta data-react-helmet="true" property="og:type" content="page"></meta>
        <meta
          data-react-helmet="true"
          property="og:title"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:url"
          content="https://consultfgc.com/"
        ></meta>
        <meta
          data-react-helmet="true"
          property="og:site_name"
          content="Consult FGC | Digital Marketing Agency | Website Design & Development"
        ></meta>
        <meta
          data-react-helmet="true"
          property="article:tag"
          content="geofencing , geofencing advertising , true-scrope"
        ></meta>
        <meta
          data-react-helmet="true"
          property="face-domain-verification"
          content="ivt9yr7ijq3ruqyq8nt02ol48zm4mu"
        ></meta>
        <link
          href="https://fonts.googleapis.com/css?family-Poppins:400,500,600,700,800&display=swap"
          rel="stylesheet"
        ></link>
        <script
          src="https://connect.facebook.net/signals/config/793714948042216?v=2.9.83&r=stable"
          async
        ></script>
      </Helmet>

      <div class="hidden lg:block desktop">
        <div class="hero bg-white">
          <div class="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-8">
            <div
              class="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
              aria-hidden="true"
            ></div>
            <header class="">
              <nav class="max-w-7xl mx-auto" aria-label="Top">
                <div class="w-full flex items-center justify-between border-b border-indigo-500 lg:border-none">
                  <div class="flex items-center">
                    <a href="/">
                      <span class="sr-only">Consult FGC</span>
                      <img
                        class="h-20 w-auto hover:opacity-70"
                        src="https://imgix.cosmicjs.com/6149ea10-10f5-11ee-af69-111aab27c387-Consult-logo-vector.svg"
                        alt=""
                      />
                    </a>
                  </div>
                  <a
                    href="tel:+18883417735"
                    class="inline-flex items-center gap-x-2 rounded-md bg-blue-700 px-10 py-4 text-sm font-semibold text-white shadow-sm hover:bg-blue-900 "
                  >
                    <svg
                      class="-ml-0.5 h-5 w-5 text-green-400"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                      fill="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                      />
                    </svg>
                    +1 (888) 341-7735
                  </a>
                </div>{" "}
              </nav>
            </header>

            <div class="mx-auto max-w-7xl pt-8 pb-28">
              <div class="mt-10">
                <h1 class="-ml-1 text-4xl font-semibold tracking-tight text-gray-900 sm:text-6xl ">
                  <span class="block">
                    You Need More{" "}
                    <span class="text-blue-800 font-bold">“YOUs.”</span> We’re
                    Here to Help.
                  </span>
                </h1>
                <p class="pt-5 text-xl leading-8 text-gray-600">
                  Do you have time to think about all of the intricacies of
                  digital marketing? Of course not!...but we do.
                </p>
                <p class="pb-10 pt-5 text-xl leading-8 text-gray-600">
                  Get more time back and expand your marketing team with just
                  one hire - Consult F.G.C. We’re a full-service digital
                  marketing agency and we can
                  <span class="text-gray-600 ">
                    {" "}
                    help you{" "}
                    <span class="underline">
                      {" "}
                      <span class="underline">expand</span> your reach,
                    </span>{" "}
                    <span class="underline">
                      <span class=" ">ease</span> your marketing workload,
                    </span>{" "}
                    and{" "}
                    <span class="underline">
                      <span class=""> get</span> qualified leads.
                    </span>
                  </span>{" "}
                </p>
              </div>

              <div class="mx-auto flex justify-between">
                <div class=" max-w-4xl ">
                  <h1 class="font-bold text-xl">
                    <span class="text-green-600 block">
                      {" "}
                      Digital marketing is intricate, but it can be simple. Get
                      somebody else to do it.
                    </span>
                    <span class="mt-6 text-green-600 block">
                      Make it our problem.
                    </span>
                  </h1>
                  <p class="pt-10 text-xl max-w-3xl leading-8 text-gray-600">
                    With us, you’ll get:
                  </p>
                  <dl class="mt-4 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-2xl">
                    <div class="relative">
                      <dt class="ml-10 font-semibold">
                        <svg
                          class="h-5 w-5 inline -mt-1 mr-4 text-green-300"
                          fill="currentColor"
                          viewBox="0 0 100 100"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="50" cy="50" r="50" />
                        </svg>
                        <p class="text-lg inline">
                          {" "}
                          25+ Years of Web Design & Marketing Experience
                        </p>
                      </dt>
                    </div>
                    <div class="relative">
                      <dt class="ml-10 font-semibold">
                        <svg
                          class="h-5 w-5 inline -mt-1 mr-4 text-green-300"
                          fill="currentColor"
                          viewBox="0 0 100 100"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="50" cy="50" r="50" />
                        </svg>
                        <p class="text-lg inline">
                          {" "}
                          Certified Digital Advertising Experts [Google Ads
                          Partners,{" "}
                          <span class="ml-10 block">
                            Meta/Facebook Ads, Microsoft/Bing Advertising, &
                            more]
                          </span>
                        </p>
                      </dt>
                    </div>
                    <div class="relative">
                      <dt class="ml-10 font-semibold">
                        <svg
                          class="h-5 w-5 inline -mt-1 mr-4 text-green-300"
                          fill="currentColor"
                          viewBox="0 0 100 100"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="50" cy="50" r="50" />
                        </svg>
                        <p class="text-lg inline">
                          {" "}
                          Programmatic & Geofencing Expertise, including access
                          to our
                          <span class="block ml-10">
                            proprietary geofencing software
                          </span>
                        </p>
                      </dt>
                    </div>
                    <div class="relative">
                      <dt class="ml-10 font-semibold">
                        <svg
                          class="h-5 w-5 inline -mt-1 mr-4 text-green-300"
                          fill="currentColor"
                          viewBox="0 0 100 100"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="50" cy="50" r="50" />
                        </svg>
                        <p class="text-lg inline">
                          {" "}
                          Custom marketing plans designed for you and your
                          business
                          <span class="ml-10 block">needs</span>
                        </p>
                      </dt>
                    </div>
                    <div class="relative">
                      <dt class="ml-10 font-semibold">
                        <svg
                          class="h-5 w-5 inline -mt-1 mr-4 text-green-300"
                          fill="currentColor"
                          viewBox="0 0 100 100"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="50" cy="50" r="50" />
                        </svg>
                        <p class="text-lg inline">
                          {" "}
                          Trustworthy, dedicated professionals
                        </p>
                      </dt>
                    </div>
                  </dl>

                  <p class="pt-12 text-xl leading-8 text-gray-600 max-w-3xl">
                    We want to help you get on the right track, or scale your
                    efforts with ease. Find out if we’re a right fit for you,
                    fill out our form to get started.
                  </p>
                  <h1 class="pt-10 font-bold text-2xl">
                    Contact us <span class="text-blue-800">TODAY.</span> Book
                    your next vacation tomorrow.{" "}
                  </h1>
                </div>
                <div class="max-w-md bg-gray-200 p-6 rounded-lg">
                  {" "}
                  <h1 class="text-center font-semibold text-2xl">
                    <span class="block opacity-40">Make the first step...</span>
                  </h1>
                  <section id="form">
                    <form
                      method="POST"
                      action="/thank-you"
                      target="_self"
                      id="Digital-Marketing-23"
                      name="Digital-Marketing-23"
                      data-netlify="true"
                      data-netlify-honeypot="bot-field"
                      class=" lg:flex-auto"
                    >
                      <input
                        type="hidden"
                        name="form-name"
                        value="Digital-Marketing-23"
                      />
                      <div class=" pb-7">
                        <div>
                          <div class="mt-6">
                            <input
                              type="text"
                              name="full-name"
                              id="full-name"
                              required
                              autocomplete="given-name"
                              class="block w-full rounded-md border-0 px-3.5 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder="Full Name*"
                            />
                          </div>
                        </div>
                        <div>
                          <div class="mt-6">
                            <input
                              type="email"
                              name="biz-email"
                              id="biz-email"
                              required
                              class="block w-full rounded-md border-0 px-3.5 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder="Business Email*"
                            />
                          </div>
                        </div>
                        <div>
                          <div class="mt-6">
                            <input
                              type="number"
                              id="phone"
                              name="phone"
                              class="block w-full rounded-md border-0 px-3.5 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder="Phone Number"
                            />
                          </div>
                        </div>

                        <div class="mt-6 sm:col-span-2">
                          <select
                            type="text"
                            name="service-requested"
                            id="service-requested"
                            class="mt-6 block w-full rounded-md border-0 px-3.5 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          >
                            <option value="" selected disabled hidden>
                              Service You’re Interested In
                            </option>
                            <option value=" Digital Marketing Services">
                              Digital Marketing Services
                            </option>
                            <option value="Geofencing">Geofencing</option>
                            <option value="Online Advertising">
                              Online Advertising
                            </option>
                            <option value=" Website Design">
                              Website Design
                            </option>
                            <option value="  Software Development">
                              Software Development
                            </option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                        <div class="mt-6 sm:col-span-2">
                          <select
                            type="text"
                            name="referral"
                            id="referral"
                            class="mt-6 block w-full rounded-md border-0 px-3.5 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          >
                            <option value="" selected disabled hidden>
                              How'd You Hear About Us
                            </option>
                            <option value="Online Search">Online Search</option>
                            <option value="Word-of-Mouth/Referral">
                              Word-of-Mouth/Referral
                            </option>
                            <option value="Online Ad">Online Ad</option>
                            <option value=" Social Media">Social Media</option>

                            <option value="Other">Other</option>
                          </select>
                        </div>
                        <div class="sm:col-span-2">
                          <div class="mt-6">
                            <textarea
                              id="message"
                              name="message"
                              rows="4"
                              aria-describedby="message-description"
                              class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder="How can we help you?    Max 500 characters"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="mt-6">
                        <button
                          type="submit"
                          class="block w-full rounded-md bg-emerald-600 px-3.5 py-4 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Submit
                        </button>
                      </div>
                      <p class="px-10 mt-4 text-xs italic text-center leading-6 text-gray-500">
                        We do not sell your information and will not overwhelm
                        you with emails.
                      </p>
                    </form>
                  </section>
                </div>
              </div>
            </div>
            <div class="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32"></div>
          </div>
        </div>
        <div class="testimonials">
          <section class="bg-gradient-to-r from-emerald-100 to-yellow-100 pt-24 pb-20">
            <div class="mx-auto max-w-7xl">
              <h1 class="-ml-1 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl ">
                Don’t Believe Us? Listen To Some{" "}
                <span class="block pt-5">Of Our Clients . . .</span>
              </h1>
              <div class="pt-20 mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                <div class="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
                  <img class="h-12 self-start" src={Achieva} alt="" />
                  <figure class="mt-10 flex flex-auto flex-col justify-between">
                    <blockquote class="text-lg leading-8 text-gray-900">
                      <p>
                        “I have worked with Consult F.G.C. for over 10 years
                        now. I am consistently amazed at how responsive and
                        professional they are. Their entire team is full of all
                        stars, projects get done, advertising produces results,
                        and I’m kept in the loop always. Can’t recommend them
                        highly enough.""
                      </p>
                    </blockquote>
                    <figcaption class="mt-10 flex items-center gap-x-6">
                      <img
                        class="h-14 w-14 rounded-full bg-gray-50"
                        src={David}
                        alt=""
                      />
                      <div class="text-base">
                        <div class="font-semibold text-gray-900">David Oak</div>
                        <div class="mt-1 text-gray-500">CMO of Achieva</div>
                      </div>
                    </figcaption>
                  </figure>
                </div>
                <div class="flex flex-col border-t border-gray-900/10 pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20">
                  <img class="h-12 self-start" src={UET} alt="" />
                  <figure class="mt-10 flex flex-auto flex-col justify-between">
                    <blockquote class="text-lg leading-8 text-gray-900">
                      <p>
                        “I have worked with Consult F.G.C. for over 8 years.
                        They have been instrumental in the management and
                        advertising of our entire brand portfolio [8 brands].
                        From website design to robust advertising programs, and
                        even an online APP they can do it all. I can’t recommend
                        Consult F.G.C. enough.”
                      </p>
                    </blockquote>
                    <figcaption class="mt-10 flex items-center gap-x-6">
                      <img
                        class="h-14 w-14 rounded-full bg-gray-50"
                        src={Mike}
                        alt=""
                      />
                      <div class="text-base">
                        <div class="font-semibold text-gray-900">
                          Mike Huggins
                        </div>
                        <div class="mt-1 text-gray-500">
                          Managing Director of UET
                        </div>
                      </div>
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>

            <div class="text-center mt-20 mb-8 ">
              <AnchorLink
                href="#form"
                class="inline-flex items-center gap-x-2 rounded-md bg-blue-800 px-14 py-5 text-lg font-semibold text-white shadow-sm hover:bg-blue-900 "
              >
                Contact Us Today
              </AnchorLink>
            </div>
          </section>
        </div>
      </div>
      <div class="block lg:hidden mobile">
        <div class="hero bg-white">
          <div class="px-3 relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20 pt-8">
            <div
              class="absolute inset-y-0 right-1/2 -z-10 -mr-96 w-[200%] origin-top-right skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:-mr-80 lg:-mr-96"
              aria-hidden="true"
            ></div>
            <header class="">
              <nav class="max-w-7xl mx-auto" aria-label="Top">
                <div class="w-full flex items-center justify-between pb-5 border-b border-indigo-500 lg:border-none">
                  <div class="flex items-center">
                    <a href="/">
                      <span class="sr-only">Consult FGC</span>
                      <img
                        class="h-20 w-auto hover:opacity-70"
                        src="https://imgix.cosmicjs.com/6149ea10-10f5-11ee-af69-111aab27c387-Consult-logo-vector.svg"
                        alt=""
                      />
                    </a>
                  </div>
                  <a
                    href="tel:+18883417735"
                    class="inline-flex items-center gap-x-2 rounded-md bg-blue-700 px-10 py-4 text-sm font-semibold text-white shadow-sm hover:bg-blue-900 "
                  >
                    <svg
                      class="-ml-0.5 h-5 w-5 text-green-400"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                      fill="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                      />
                    </svg>
                    +1 (888) 341-7735
                  </a>
                </div>{" "}
              </nav>
            </header>

            <div class="mx-auto max-w-7xl pt-8 pb-28">
              <div class="mt-10">
                <h1 class="-ml-1 text-4xl font-semibold tracking-tight text-gray-900 sm:text-6xl ">
                  <span class="block">
                    You Need More{" "}
                    <span class="text-blue-800 font-bold">“YOUs.”</span> We’re
                    Here to Help.
                  </span>
                </h1>
                <p class="pt-5 text-xl leading-8 text-gray-600">
                  Do you have time to think about all of the intricacies of
                  digital marketing? Of course not!...but we do.
                </p>
                <p class="pb-10 pt-5 text-xl leading-8 text-gray-600">
                  Get more time back and expand your marketing team with just
                  one hire - Consult F.G.C. We’re a full-service digital
                  marketing agency and we can
                  <span class="text-gray-600 ">
                    {" "}
                    help you{" "}
                    <span class="underline">
                      {" "}
                      <span class="underline">expand</span> your reach,
                    </span>{" "}
                    <span class="underline">
                      <span class=" ">ease</span> your marketing workload,
                    </span>{" "}
                    and{" "}
                    <span class="underline">
                      <span class=""> get</span> qualified leads.
                    </span>
                  </span>{" "}
                </p>
              </div>

              <div class="mx-auto">
                <div class=" max-w-4xl ">
                  <h1 class="font-bold text-xl">
                    <span class="text-green-600 block">
                      {" "}
                      Digital marketing is intricate, but it can be simple. Get
                      somebody else to do it.
                    </span>
                    <span class="mt-6 text-green-600 block">
                      Make it our problem.
                    </span>
                  </h1>
                  <p class="pt-10 text-xl max-w-3xl leading-8 text-gray-600">
                    With us, you’ll get:
                  </p>
                  <dl class="mt-4 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-2xl">
                    <div class="relative">
                      <dt class="ml-10 font-semibold">
                        <svg
                          class="h-5 w-5 inline -mt-1 mr-4 text-green-300"
                          fill="currentColor"
                          viewBox="0 0 100 100"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="50" cy="50" r="50" />
                        </svg>
                        <p class="text-lg inline">
                          {" "}
                          25+ Years of Web Design & Marketing{" "}
                          <span class="ml-10 block"> Experience</span>
                        </p>
                      </dt>
                    </div>
                    <div class="relative">
                      <dt class="ml-10 font-semibold">
                        <svg
                          class="h-5 w-5 inline -mt-1 mr-4 text-green-300"
                          fill="currentColor"
                          viewBox="0 0 100 100"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="50" cy="50" r="50" />
                        </svg>
                        <p class="text-lg inline">
                          {" "}
                          Certified Digital Advertising Experts
                          <span class="ml-10 block">
                            [Google Ads Partners, Meta/Facebook Ads,
                            Microsoft/Bing Advertising, & more]
                          </span>
                        </p>
                      </dt>
                    </div>
                    <div class="relative">
                      <dt class="ml-10 font-semibold">
                        <svg
                          class="h-5 w-5 inline -mt-1 mr-4 text-green-300"
                          fill="currentColor"
                          viewBox="0 0 100 100"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="50" cy="50" r="50" />
                        </svg>
                        <p class="text-lg inline">
                          {" "}
                          Programmatic & Geofencing Expertise,
                          <span class="block ml-10">
                            including access to our proprietary geofencing
                            software
                          </span>
                        </p>
                      </dt>
                    </div>
                    <div class="relative">
                      <dt class="ml-10 font-semibold">
                        <svg
                          class="h-5 w-5 inline -mt-1 mr-4 text-green-300"
                          fill="currentColor"
                          viewBox="0 0 100 100"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="50" cy="50" r="50" />
                        </svg>
                        <p class="text-lg inline">
                          {" "}
                          Custom marketing plans designed for
                          <span class="ml-10 block">
                            you and your business needs
                          </span>
                        </p>
                      </dt>
                    </div>
                    <div class="relative">
                      <dt class="ml-10 font-semibold">
                        <svg
                          class="h-5 w-5 inline -mt-1 mr-4 text-green-300"
                          fill="currentColor"
                          viewBox="0 0 100 100"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="50" cy="50" r="50" />
                        </svg>
                        <p class="text-lg inline">
                          {" "}
                          Trustworthy, dedicated professionals
                        </p>
                      </dt>
                    </div>
                  </dl>

                  <p class="pt-12 text-xl leading-8 text-gray-600 max-w-3xl">
                    We want to help you get on the right track, or scale your
                    efforts with ease. Find out if we’re a right fit for you,
                    fill out our form to get started.
                  </p>
                  <h1 class="pt-10 font-bold text-2xl">
                    Contact us <span class="text-blue-800">TODAY.</span> Book
                    your next vacation tomorrow.{" "}
                  </h1>
                </div>
              </div>
            </div>
            <div class="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32"></div>
          </div>
        </div>
        <div class="testimonials">
          <section class="bg-gradient-to-r from-emerald-100 to-yellow-100 pt-24 pb-20">
            <div class="px-3 mx-auto max-w-7xl">
              <h1 class="-ml-1 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl ">
                Don’t Believe Us? Listen To Some{" "}
                <span class="block pt-5">Of Our Clients . . .</span>
              </h1>
              <div class="pt-20 mx-auto grid max-w-2xl grid-cols-1 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                <div class="flex flex-col pb-10 sm:pb-16 lg:pb-0 lg:pr-8 xl:pr-20">
                  <img class="h-12 self-start" src={Achieva} alt="" />
                  <figure class="mt-10 flex flex-auto flex-col justify-between">
                    <blockquote class="text-lg leading-8 text-gray-900">
                      <p>
                        “I have worked with Consult F.G.C. for over 10 years
                        now. I am consistently amazed at how responsive and
                        professional they are. Their entire team is full of all
                        stars, projects get done, advertising produces results,
                        and I’m kept in the loop always. Can’t recommend them
                        highly enough.""
                      </p>
                    </blockquote>
                    <figcaption class="mt-10 flex items-center gap-x-6">
                      <img
                        class="h-14 w-14 rounded-full bg-gray-50"
                        src={David}
                        alt=""
                      />
                      <div class="text-base">
                        <div class="font-semibold text-gray-900">David Oak</div>
                        <div class="mt-1 text-gray-500">CMO of Achieva</div>
                      </div>
                    </figcaption>
                  </figure>
                </div>
                <div class="flex flex-col border-t border-gray-900/10 pt-10 sm:pt-16 lg:border-l lg:border-t-0 lg:pl-8 lg:pt-0 xl:pl-20">
                  <img class="h-12 self-start" src={UET} alt="" />
                  <figure class="mt-10 flex flex-auto flex-col justify-between">
                    <blockquote class="text-lg leading-8 text-gray-900">
                      <p>
                        “I have worked with Consult F.G.C. for over 8 years.
                        They have been instrumental in the management and
                        advertising of our entire brand portfolio [8 brands].
                        From website design to robust advertising programs, and
                        even an online APP they can do it all. I can’t recommend
                        Consult F.G.C. enough.”
                      </p>
                    </blockquote>
                    <figcaption class="mt-10 flex items-center gap-x-6">
                      <img
                        class="h-14 w-14 rounded-full bg-gray-50"
                        src={Mike}
                        alt=""
                      />
                      <div class="text-base">
                        <div class="font-semibold text-gray-900">
                          Mike Huggins
                        </div>
                        <div class="mt-1 text-gray-500">
                          Managing Director of UET
                        </div>
                      </div>
                    </figcaption>
                  </figure>
                </div>
              </div>
            </div>

            <div class="hidden text-center mt-20 mb-8 ">
              <AnchorLink
                href="#form"
                class="inline-flex items-center gap-x-2 rounded-md bg-blue-800 px-14 py-5 text-lg font-semibold text-white shadow-sm hover:bg-blue-900 "
              >
                Contact Us Today
              </AnchorLink>
            </div>
          </section>
        </div>
        <div class="mx-auto mt-8 max-w-md bg-gray-200 p-6 rounded-lg">
          {" "}
          <h1 class="text-center font-semibold text-2xl">
            <span class="block opacity-40">Make the first step...</span>
          </h1>
          <section id="form">
            <form
              method="POST"
              action="/thank-you"
              target="_self"
              id="Digital-Marketing-23"
              name="Digital-Marketing-23"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              class=" lg:flex-auto"
            >
              <input
                type="hidden"
                name="form-name"
                value="Digital-Marketing-23"
              />
              <div class=" pb-7">
                <div>
                  <div class="mt-6">
                    <input
                      type="text"
                      name="full-name"
                      id="full-name"
                      required
                      autocomplete="given-name"
                      class="block w-full rounded-md border-0 px-3.5 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Full Name*"
                    />
                  </div>
                </div>
                <div>
                  <div class="mt-6">
                    <input
                      type="email"
                      name="biz-email"
                      id="biz-email"
                      required
                      class="block w-full rounded-md border-0 px-3.5 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Business Email*"
                    />
                  </div>
                </div>
                <div>
                  <div class="mt-6">
                    <input
                      type="number"
                      id="phone"
                      name="phone"
                      class="block w-full rounded-md border-0 px-3.5 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="Phone Number"
                    />
                  </div>
                </div>

                <div class="mt-6 sm:col-span-2">
                  <select
                    type="text"
                    name="service-requested"
                    id="service-requested"
                    class="mt-6 block w-full rounded-md border-0 px-3.5 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="" selected disabled hidden>
                      Service You’re Interested In
                    </option>
                    <option value=" Digital Marketing Services">
                      Digital Marketing Services
                    </option>
                    <option value="Geofencing">Geofencing</option>
                    <option value="Online Advertising">
                      Online Advertising
                    </option>
                    <option value=" Website Design">Website Design</option>
                    <option value="  Software Development">
                      Software Development
                    </option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div class="mt-6 sm:col-span-2">
                  <select
                    type="text"
                    name="referral"
                    id="referral"
                    class="mt-6 block w-full rounded-md border-0 px-3.5 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="" selected disabled hidden>
                      How'd You Hear About Us
                    </option>
                    <option value="Online Search">Online Search</option>
                    <option value="Word-of-Mouth/Referral">
                      Word-of-Mouth/Referral
                    </option>
                    <option value="Online Ad">Online Ad</option>
                    <option value=" Social Media">Social Media</option>

                    <option value="Other">Other</option>
                  </select>
                </div>
                <div class="sm:col-span-2">
                  <div class="mt-6">
                    <textarea
                      id="message"
                      name="message"
                      rows="4"
                      aria-describedby="message-description"
                      class="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="How can we help you?    Max 500 characters"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="mt-6">
                <button
                  type="submit"
                  class="block w-full rounded-md bg-emerald-600 px-3.5 py-4 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Submit
                </button>
              </div>
              <p class="px-10 mt-4 text-xs italic text-center leading-6 text-gray-500">
                We do not sell your information and will not overwhelm you with
                emails.
              </p>
            </form>
          </section>
        </div>
      </div>

      <footer class="bg-white py-3 border-t border-gray-100">
        <div class="text-center md:text-left py-5 max-w-7xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div class="">
            <p class="pb-2 text-base text-gray-400">Get In Touch</p>
            <h1 class="text-4xl tracking-wide font-bold text-primaryBlue">
              <a class="hover:underline" href="tel:8883417735">
                {" "}
                888.341.7735
              </a>
            </h1>
            <h1 class="mt-3 mb-10 md:mb-0 md:text-3xl tracking-wide font-medium text-teal-500">
              <a class="hover:underline" href="mailto:marketing@consultfgc.com">
                {" "}
                marketing@consultfgc.com
              </a>
            </h1>
          </div>
          <div class="flex items-center justify-center">
            <div class="relative">
              {" "}
              <a
                class="w-2/3 block hover:opacity-70"
                href="https://www.google.com/partners/agency?id=4727175905"
                target="_blank"
              >
                <img
                  class=""
                  src="https://www.gstatic.com/partners/badge/images/2022/PartnerBadgeClickable.svg"
                />
              </a>
            </div>
            <div class=" -ml-6 relative">
              {" "}
              <a
                class="hover:opacity-70"
                href="https://ugecredits.com/"
                target="_blank"
              >
                <img
                  class="w-24 block"
                  src="https://imgix.cosmicjs.com/d7d9c380-f224-11ec-a2eb-c1653f3f9199-Circle-badge.svg"
                />
              </a>
            </div>
          </div>
        </div>

        <div class="copyright border-gray-100 py-5  border-t max-w-7xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div class="flex justify-center space-x-6 md:order-2">
            <a
              href="https://www.facebook.com/ConsultFGC/"
              class="text-gray-400 hover:text-gray-500"
            >
              <span class="sr-only">Facebook</span>
              <svg
                class="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/consult_fgc"
              class="text-gray-400 hover:text-gray-500"
            >
              <span class="sr-only">Instagram</span>
              <svg
                class="h-5 w-5 mt-0.5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>

            <a
              href="https://www.linkedin.com/company/consult-fgc/"
              class="text-gray-400 hover:text-gray-500"
            >
              <span class="sr-only">Linkedin</span>
              <svg
                class="h-6 w-6 pt-0.5 pb-0.5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </div>

          <div class="mt-8 md:mt-0 md:order-1">
            <p class="text-center text-base text-gray-400">
              &copy; {new Date().getFullYear()} ConsultFGC,
              <a class="text-blue-400 hover:underline" href="/capabilities">
                <span> GSA Capabilities</span>
              </a>{" "}
            </p>
          </div>
        </div>

        <div class="text-center space-x-2 md:text-left max-w-7xl mx-auto px-4 sm:px-6 md:flex md:items-center lg:px-8">
          <a href="/terms-of-service">
            <span class="text-gray-400 hover:text-blue-400 hover:underline">
              Terms and Conditions
            </span>
          </a>
          <a href="/ccpa">
            <span class="text-gray-400 hover:text-blue-400 hover:underline">
              {" "}
              CCPA
            </span>
          </a>
          <a href="/privacy-policy">
            <span class="text-gray-400 hover:text-blue-400 hover:underline">
              {" "}
              Privacy Policy{" "}
            </span>
          </a>
        </div>
      </footer>
    </>
  );
};

export default MarketingPartners;
